import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import appMixins_oTliCwEPNe8AE9vcmHcPPR1YWrv70_m9SAVV_0bRGi4 from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/plugins/appMixins.js";
import global_router_udpooHiec8yzOkDu_tBQP_dH_CCgmnFGg8pQSZPiClM from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/plugins/global-router.js";
import global_scrollbar_FsFcX8gJ5_Lnsf5ok4YXAUgnrhwjmNl0xY0kAXWe8OE from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/plugins/global-scrollbar.js";
import error_handler__pSwgjkjHyQz48eWe45OxLaABCs4sek7eLtYHu9yC1k from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/plugins/error-handler.js";
import i18n_SMdHjDdqIId1QK07LHAPEyr3HZfMyKcn2rAtnZisyJE from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/plugins/i18n.js";
import gtm_client_FmyIzOQfnL7kfwKGOuBFBaPlhm1W5ajGN2WqAk_C3eg from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/plugins/gtm.client.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk,
  appMixins_oTliCwEPNe8AE9vcmHcPPR1YWrv70_m9SAVV_0bRGi4,
  global_router_udpooHiec8yzOkDu_tBQP_dH_CCgmnFGg8pQSZPiClM,
  global_scrollbar_FsFcX8gJ5_Lnsf5ok4YXAUgnrhwjmNl0xY0kAXWe8OE,
  error_handler__pSwgjkjHyQz48eWe45OxLaABCs4sek7eLtYHu9yC1k,
  i18n_SMdHjDdqIId1QK07LHAPEyr3HZfMyKcn2rAtnZisyJE,
  gtm_client_FmyIzOQfnL7kfwKGOuBFBaPlhm1W5ajGN2WqAk_C3eg
]