import Scrollbar from 'smooth-scrollbar'

export default defineNuxtPlugin(nuxtApp => {

    class EdgeEasingPlugin extends Scrollbar.ScrollbarPlugin {
        static pluginName = "edgeEasing";
        _remainMomentum = {
            x: 0,
            y: 0,
        };

        transformDelta(delta) {
            const { limit, offset, _momentum } = this.scrollbar;
            const x = this._remainMomentum.x + delta.x;
            const y = this._remainMomentum.y + delta.y;
            const handleMomentumX = Math.max(-offset.x, Math.min(x, limit.x - offset.x))
            const handleMomentumy = Math.max(-offset.y, Math.min(y, limit.y - offset.y))
            if (limit.y < 1500) {
                this.scrollbar.setMomentum(
                    Math.max(-offset.x, Math.min(delta.x, limit.x - offset.x)),
                    Math.max(-offset.y, Math.min(delta.y, limit.y - offset.y))
                );
                return {
                    x: Math.max(-offset.x, Math.min(delta.x, limit.x - offset.x)),
                    y: handleMomentumy / 1.1,
                };
            } else {
                if (offset.y < 200 && _momentum.y < 0) {
                    this.scrollbar.setMomentum(
                        handleMomentumX,
                        handleMomentumy
                    );
                    return { x: 0, y: 0 };
                }

                if (offset.y > limit.y - 200 && _momentum.y > 0) {
                    this.scrollbar.setMomentum(
                        handleMomentumX,
                        handleMomentumy
                    );
                    return { x: 0, y: 0 };
                }
            }
        }

        onRender(remainMomentum) {
            Object.assign(this._remainMomentum, remainMomentum);
        }
    }

    Scrollbar.use(EdgeEasingPlugin);
})

