// plugins/gtm.client.ts

export default defineNuxtPlugin((nuxtApp) => {
    
    if ( import.meta.server) return

    const gtmId = 'GTM-TMKDWCC'

    useHead({
        script: [
            {
                src: `https://www.googletagmanager.com/gtm.js?id=${gtmId}`,
                async: true,
                onError: "console.error('Failed to load GTM script.')"
            }
        ]
    })

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })

    if ( import.meta.client) {
        document.cookie = `gtm_cookie=value; Path=/; Domain=.${window.location.hostname}; Secure; SameSite=None`
    }

    return {
        provide: {
            gtm: {
                trackEvent(event, payload) {
                    if ( import.meta.client) {
                        window.dataLayer.push({ event, ...payload })
                    }
                },
            },
        },
    }
})