<script setup>
import copyright from "@/utils/copyright.js";
import EmailSubscribe from "./EmailSubscribe.vue";
import OtherFooterList from "./OtherFooterList.vue";
import { useCategsStore } from "@/store/categs";

const categs = useCategsStore();

const { discover, contact } = categs.categs;

const company = ref([
  { title: "Company", path: "/company", exist: true },
  { title: "Governance", path: "/company/governance", exist: true },
  { title: "Awards", path: "/company/awards", exist: true },
  { title: "Sustainability", path: "/company/sustainability", exist: true },
  { title: "Become_a_Partner", path: "/company/become-partner", exist: true },
  { title: "Rates", path: "/company/rates", exist: true },
]);

const wealth = ref([
  { title: "Gold_and_Silver", path: "/products/gold-silver", exist: true },
  { title: "Benefits", path: "/products/benefits", exist: true },
  { title: "Vault_and_Trust", path: "/products/vault-trust", exist: true },
  { title: "Advisory", path: "/products/advisory", exist: true },
]);

const legal = ref([
  { path: "/legal/vqf-membership", title: "VQF_Membership", exist: true },
  {
    path: "goldensuisse_terms.pdf",
    title: "Terms_and_Conditions",
    pdf: true,
    exist: true,
  },
  { path: "goldensuisse_aml.pdf", title: "AML", pdf: true, exist: true },
  { path: "/legal/privacy", title: "Privacy", exist: true },
  {
    path: "goldensuisse_privacypolicy.pdf",
    title: "Privacy_Policy",
    pdf: true,
    exist: true,
  },
  { path: "/legal/information", title: "Information", exist: true },
]);

const account = ref([
  { path: "/account", title: "Login", exist: true },
  { title: "Open_an_Account", path: "/account/register", exist: true },
  { path: "/account/download", title: "Download_Apps", exist: true },
]);

const redirect = (path) => {
  window.open(path);
};
</script>

<template>
  <div class="footer">
    <div class="footer__emailSubscribe" data-anim="fadeIn" data-anim-delay=".3">
      <EmailSubscribe />
    </div>

    <div class="footer__block2" data-anim="fadeIn" data-anim-delay=".4">
      <OtherFooterList title="Contact" :content="contact" />
    </div>

    <div class="footer__block3" data-anim="fadeIn" data-anim-delay=".5">
      <OtherFooterList title="About" :content="company" />
    </div>

    <div class="footer__blockWealth" data-anim="fadeIn" data-anim-delay=".5">
      <OtherFooterList title="Wealth_Protection" :content="wealth" />
    </div>

    <div class="footer__blockDiscover" data-anim="fadeIn" data-anim-delay=".5">
      <OtherFooterList title="Discover" :content="discover" />
    </div>

    <div class="footer__block5" data-anim="fadeIn" data-anim-delay=".3">
      <OtherFooterList title="Legal" :content="legal" />
    </div>

    <div class="footer__block6" data-anim="fadeIn" data-anim-delay=".4">
      <OtherFooterList title="My_account" :content="account" />
    </div>

    <div class="footer__appStore" data-anim="fadeIn" data-anim-delay=".5">
      <NuxtImg
        class="footer__appStore_img"
        src="/image/footer/appstore-logo.png"
        @click="
          redirect('https://apps.apple.com/us/app/goldensuisse/id1236821991')
        "
      />
    </div>

    <div class="footer__social" data-anim="fadeIn" data-anim-delay=".5">
      <NuxtImg
        class="footer__social_img"
        src="/image/footer/google-play-logo.png"
        @click="
          redirect(
            'https://play.google.com/store/apps/details?id=com.golden.coin&hl=en_US'
          )
        "
      />
    </div>

    <div class="footer__info" data-anim="fadeIn" data-anim-delay=".5">
      {{ copyright() + $t("copyright") }}
    </div>
  </div>
</template>
<style lang="scss" scoped>
.footer {
  display: grid;
  grid-template-areas:
    ". emailSubscribe emailSubscribe . . . discover wealth  block3 ."
    ". . . . . . block2 block5 block6 ."
    ". appStore social info info info info . . .";
  grid-template-columns: 3.75% repeat(8, 11.5625%) 3.75%;
  padding: 6.3% 0 2.5% 0;
  height: max-content;

  &__emailSubscribe {
    grid-area: emailSubscribe;
  }

  &__block1 {
    grid-area: block1;
  }

  &__block2 {
    grid-area: block2;
  }

  &__block3 {
    grid-area: block3;
  }

  &__block4 {
    grid-area: block4;
  }

  &__block5 {
    grid-area: block5;
  }

  &__block6 {
    grid-area: block6;
  }

  &__blockWealth {
    grid-area: wealth;
  }

  &__blockDiscover {
    grid-area: discover;
  }

  &__appStore {
    grid-area: appStore;
    display: flex;
    align-items: center;

    &_img {
      width: 80%;
      cursor: pointer;
    }
  }

  &__social {
    grid-area: social;
    display: flex;
    align-items: center;

    &_img {
      transform: translateX(-10%);
      width: 80%;
      cursor: pointer;
    }
  }

  &__info {
    grid-area: info;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 214%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -0.02em;
    color: $darkGrey;
  }

  // @include adaptive(tablet) {
  //   padding-bottom: 0;
  // }
}

@media (max-width: 1023px) {
  .footer {
    grid-template-areas:
      ". emailSubscribe emailSubscribe ."
      ". discover . ."
      ". wealth block3 ."
      ". block2 block4 ."
      ". block5 block6 ."
      ". appStore social ."
      ". info info .";
    grid-template-columns: 5.4% repeat(2, 44.6%) 5.4%;
    padding: 80px 0 0 0;

    &__block1,
    &__block2,
    &__block3,
    &__block4,
    &__block5,
    &__block6 {
      display: flex;
      justify-content: flex-start;
      padding-top: 60px;
    }

    &__blockDiscover,
    &__blockWealth {
      padding-top: 60px;
    }

    &__appStore {
      display: flex;
      justify-content: flex-end;
      padding-top: 32%;
      padding-right: 5.98%;

      &_img {
        width: 142px;
      }
    }

    &__social {
      display: flex;
      justify-content: flex-start;
      padding-top: 32%;
      padding-left: 13.76%;

      &_img {
        width: 142px;
      }
    }

    &__emailSubscribe {
      padding: 0;
    }

    &__info {
      padding: 17% 0 3% 0;
    }
  }
}
</style>
