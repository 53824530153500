import { createI18n } from 'vue-i18n'

export default defineNuxtPlugin(async (nuxtApp) => {
    const messages = {
      en: await import('@/locales/en.json'),
      // zh: await import('@/locales/zh.json'),
      // ar: await import('@/locales/ar.json'),
      // ge: await import('@/locales/ge.json'),
      // fr: await import('@/locales/fr.json'),
      // it: await import('@/locales/it.json'),
      // pt: await import('@/locales/pt.json'),
      // ru: await import('@/locales/ru.json'),
      // sp: await import('@/locales/sp.json'),
    }
    
    const i18n = createI18n({
      legacy: false,
      globalInjection: true,
      locale: 'en',
      fallbackLocale: 'en',
      messages
    })
  
    nuxtApp.vueApp.use(i18n)
  })