<script setup>
const FlowButton = defineAsyncComponent(() => import('~/components/global/FlowButton.vue'))

</script>

<template>
  <div class="SmallNavbar">
    <div class="SmallNavbar__login">
      <FlowButton label="My_account" path="/account" />
    </div>
  </div>
</template>

<style lang="scss" scoped>

.SmallNavbar {
  display: grid;
  grid-template-areas: "links login";
  grid-template-columns: repeat(2, 50%);

  &__login {
    grid-area: login;
    pointer-events: auto;
  }
}
</style>
