<script setup>
import { useMainStore } from "@/store";

// Props
const props = defineProps({
  isMenu: Boolean,
});
const emit = defineEmits(["close-menu", "open-menu"]);

// State
const toggleClose = ref(false);
const store = useMainStore();

const menuClick = () => {
  toggleClose.value = !toggleClose.value;
  const action = toggleClose.value ? "open-menu" : "close-menu";
  emit(action);
};

watch(
  () => props.isMenu,
  (newValue) => {
    if (!newValue) {
      toggleClose.value = false;
    }
  }
);

</script>

<template>
  <svg
    class="menu"
    @click="menuClick"
    :class="{ menu_closeHeight: toggleClose }"
    data-anim="animateMenu"
  >
    <line
      class="menu__rect"
      x1="0"
      x2="0"
      y1="1"
      y2="1"
      :class="{ menu__rect_animationCloseTop: toggleClose }"
    />
    <line
      class="menu__rect"
      x1="60"
      x2="60"
      y1="13"
      y2="13"
      :class="{ menu__rect_animationCloseBot: toggleClose }"
    />
  </svg>
</template>

<style lang="scss" scoped>
.menu {
  width: 60px;
  height: 14px;
  animation: slidein 0.1s;
  cursor: pointer;
  pointer-events: auto;
  overflow: visible;
  padding: 0;
  margin: 0;

  &_closeHeight {
    height: 60px;
  }

  &__rect {
    stroke: $white;
    stroke-width: 2px;
    transform: translateX(0) translateY(0) rotate(0);
    transition: all 0.4s ease-in-out;

    &_animationCloseTop {
      transform: translateX(60px) translateY(0) rotate(135deg);
    }

    &_animationCloseBot {
      transform: translateY(-10px) translateX(25px) rotate(45deg);
    }
  }

  @keyframes slidein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

@media (max-width: 1023px) {
  .menu {
    transform: scale(0.6666);
  }
}
</style>
