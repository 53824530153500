import { defineStore } from 'pinia';

export const useMainStore = defineStore('main', {
  state: () => ({
    windowLoaded: false,
    mainLoaderActive: false,
    mainLoaderActiveClass: false,
    isMobile: false,
    loadingStatus: true,
    timeout: null,
    firstSmall: true,
    isMenu: false,
    transEnd: false,
    loginForm: {
      username: false,
      password: false,
      qr_data: false,
      qr_uniquecode: false
    },
    scrollBlack: 0,
    prevRoute: null,
    isFooter: true,
    emailSubscribe: false,
    totalDelay: 0.3,
    totalDuration: 0.7,
    videoPopupShow: false,
    videoPopup: {
      src: '/video/gs.mp4',
      poster: '/image/video_pic/poster.jpg',
      posterXs: '/image/video_pic/poster_xs.jpg',
      srcXs: '/video/gsXs.mp4',
      desc: 'World Class Private Wealth Protection'
    },
    videoPopupInterval: '',
    closeMenuInit: false,
    platform: "",
    appFlag: false,
    initResize: false,
    metaTitle: "Golden Suisse",
  }),
  actions: {
    setWindowLoaded() {
      this.windowLoaded = true;
    },
    setMainLoaderActive(payload) {
      this.mainLoaderActive = payload;
    },
    setMainLoaderActiveClass(payload) {
      this.mainLoaderActiveClass = payload;
    },
    setIsMobile(payload) {
      this.isMobile = payload;
    },
    updateLoadingStatus(loadingStatus) {
      this.loadingStatus = loadingStatus;
    },
    clearTime() {
      clearTimeout(this.timeout);
    },
    setTime(payload) {
      this.timeout = payload;
    },
    setFirstSmall(firstSmall) {
      this.firstSmall = firstSmall;
    },
    setMenuStatus(menuStatus) {
      this.isMenu = menuStatus;
    },
    setTransEnd(payload) {
      this.transEnd = payload;
    },
    setLoginForm(payload) {
      this.loginForm = payload;
    },
    setScrollHeight(payload) {
      this.scrollBlack = payload;
    },
    setPrevRoute(payload) {
      this.prevRoute = payload;
    },
    changeFooter(payload) {
      this.isFooter = payload;
    },
    setEmailSubscribe(payload) {
      this.emailSubscribe = payload;
    },
    setVideoPopupShow(payload) {
      this.videoPopupShow = payload;
    },
    initVideoPopup(payload) {
      this.videoPopup = payload;
    },
    setVideoPopupInterval(payload) {
      this.videoPopupInterval = payload;
    },
    setCloseMenuInit(payload) {
      this.closeMenuInit = payload;
    },
    setPlatform(payload) {
      this.platform = payload;
    },
    setAppFlag(payload) {
      this.appFlag = payload;
    },
    setInitResize(payload) {
      this.initResize = payload;
    },
    setMetaTitle(payload) {
      this.metaTitle = payload;
    },
  },
  getters: {
    getLoginForm(state) {
      return state.loginForm;
    }
  },
});