<script setup>
import { useMainStore } from "@/store";
import Scrollbar from "smooth-scrollbar";
import copyright from "@/utils/copyright";
import EmailSubscribe from "@/components/layout/Other/Footer/EmailSubscribe.vue";
import OtherMenuList from "./OtherMenuList.vue";
import OtherFooter from "@/components/layout/Other/Footer/OtherFooter.vue";
import { useCategsStore } from "@/store/categs";

const FlowButton = defineAsyncComponent(() =>
  import("~/components/global/FlowButton.vue")
);

const store = useMainStore();
const categs = useCategsStore();

const { discover, company, contact, legal, account } = categs.categs;

const wealth = ref([
  {
    title: "Gold_and_Silver",
    path: "/products/gold-silver",
    exist: true,
  },
  {
    title: "Benefits",
    path: "/products/benefits",
    exist: true,
  },
  {
    title: "Vault_and_Trust",
    path: "/products/vault-trust",
    exist: true,
  },
  {
    title: "Advisory",
    path: "/products/advisory",
    exist: true,
  },
]);

onMounted(() => {
  if (!store.isMobile) {
    let menuPage = document.querySelector(".menuPage");
    if (!menuPage) return;
    menuPage.style.height = "100vh";
    Scrollbar.init(menuPage, { damping: 0.07 });
  }
});

onBeforeUnmount(() => {
  if (store.isMobile) {
    window.scrollTo(0, store.scrollBlack);
  }
});
</script>

<template>
  <div class="menuPage">
    <div class="menu">
      <section class="menu__title" data-anim="fadeIn" data-anim-delay="0">
        {{ $t("Menu") }}
      </section>
      <hr class="menu__hrTitle" height="2" />
      <section class="menu__createAcc">
        <div class="menu__createAcc_container">
          <FlowButton label="My_account" path="/account" />
        </div>
      </section>

      <section class="menu__myAcc" data-anim="fadeIn" data-anim-delay="0">
        <OtherMenuList title="My_account" :list="account" />
        <img
          alt="AppIcon"
          class="app-icon"
          src="/image/header/AppIcon.png"
          format="webp"
        />
      </section>

      <section class="menu__discover" data-anim="fadeIn" data-anim-delay=".05">
        <OtherMenuList title="Discover" :list="discover" />
      </section>

      <section class="menu__wealth" data-anim="fadeIn" data-anim-delay=".05">
        <OtherMenuList title="Wealth_Protection" :list="wealth" />
      </section>

      <section class="menu__cardImg" data-anim="fadeIn" data-anim-delay=".05">
        <img
          alt="creditcardBlackGS"
          src="/image/header/creditcardBlackGS.png"
          format="webp"
        />
      </section>

      <section class="menu__coins" data-anim="fadeIn" data-anim-delay=".05">
        <img
          alt="gold_and_silver_coin"
          src="/image/header/gold_and_silver_coin.png"
          width="185"
          height="110"
          format="webp"
        />
      </section>

      <section
        class="menu__goldenSuisse"
        data-anim="fadeIn"
        data-anim-delay=".05"
      >
        <OtherMenuList title="Company" :list="company" />
      </section>

      <hr class="menu__hrProducts" height="2" />

      <section class="menu__contact" data-anim="fadeIn" data-anim-delay=".3">
        <OtherMenuList title="Contact" :list="contact" />
      </section>

      <section class="menu__legal" data-anim="fadeIn" data-anim-delay=".3">
        <OtherMenuList title="VQF_Membership" :list="legal" />
      </section>

      <section class="menu__emailSub" data-anim="fadeIn" data-anim-delay=".3">
        <EmailSubscribe />
      </section>

      <section class="menu__footer" data-anim="fadeIn" data-anim-delay=".3">
        {{ copyright() + $t("copyright") }}
      </section>

      <section class="menu__footerComp">
        <OtherFooter />
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.menu {
  display: grid;
  grid-template-areas:
    ". . . . . . . myAcc myAcc ."
    ". title title title title title title title title ."
    ". hrTitle hrTitle hrTitle hrTitle hrTitle hrTitle hrTitle hrTitle ."
    ". discover discover . wealth wealth . goldenSuisse goldenSuisse ."
    ". card card . coins coins . . . ."
    ". hrProducts hrProducts hrProducts hrProducts hrProducts hrProducts hrProducts hrProducts ."
    ". insights insights . contact contact . legal legal ."
    ". emailSub emailSub . . . . legal legal ."
    ". footer footer footer footer footer footer footer footer .";
  grid-template-columns: 3.75% repeat(8, 11.5625%) 3.75%;
  padding-bottom: 60px;
  width: 100%;
  overflow: hidden;

  &__title {
    grid-area: title;
    font-style: normal;
    font-weight: normal;
    font-size: 180px;
    line-height: 39%;
    letter-spacing: -0.04em;
    color: $white;
    padding: 0 0 7.8% 0;
  }

  &__hrTitle {
    grid-area: hrTitle;
  }

  &__createAcc {
    grid-area: createAcc;
    display: none;
  }

  &__myAcc {
    grid-area: myAcc;
    padding: 150px 0 21% 0;
  }

  &__products {
    grid-area: products;
    padding: 20% 0 24% 0;
  }

  &__goldenSuisse {
    grid-area: goldenSuisse;
    padding: 20% 0 24% 0;
  }

  &__hrProducts {
    grid-area: hrProducts;
    margin-top: 60px;
  }

  &__insights {
    grid-area: insights;
    padding: 20% 0;
  }

  &__contact {
    grid-area: contact;
    padding: 20% 0;
  }

  &__legal {
    grid-area: legal;
    padding: 20% 0;
  }

  &__discover {
    grid-area: discover;
    padding: 20% 0 24% 0;
  }

  &__wealth {
    grid-area: wealth;
    padding: 20% 0;
  }
  &__cardImg {
    grid-area: card;
    img {
      max-width: 172px;
    }
    @include adaptive(mob) {
      border-bottom: 2px #fff solid;
      padding-bottom: 40px;
    }
  }
  &__coins {
    grid-area: coins;
    @include adaptive(mob) {
      border-bottom: 2px #fff solid;
      padding-bottom: 40px;
    }
  }

  &__emailSub {
    grid-area: emailSub;
  }

  &__footer {
    grid-area: footer;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 214%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -0.02em;
    color: $darkGrey;
    padding: 0;
    padding-top: 12%;
    padding-bottom: 3.4%;
  }

  &__footerComp {
    display: none;
  }
}

@media (max-width: 1023px) {
  .menu {
    padding: 2% 0 20px 0;
    grid-template-areas:
      ". createAcc ."
      ". myAcc ."
      ". discover ."
      ". card ."
      ". wealth ."
      ". coins ."
      ". goldenSuisse ."
      "footerComp footerComp footerComp";
    grid-template-columns: 5.4% 89.2% 5.4%;
    overflow: scroll;

    &__title {
      display: none;
    }

    &__createAcc {
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 0;

      &_container {
        width: 100%;
        max-width: 370px;
      }
    }

    &__myAcc {
      justify-content: flex-start;
      padding: 22% 0 16.5% 0;
      border-bottom: 2px $white solid;
    }

    &__products {
      padding: 17% 0;
    }

    &__goldenSuisse {
      padding: 17% 0 9% 0;
      border: none;
    }

    &__insights,
    &__contact,
    &__legal,
    &__emailSub,
    &__footer {
      display: none;
    }

    &__footerComp {
      display: block;
      grid-area: footerComp;
    }
  }
}

.app-icon {
  max-width: 40px;
  margin-top: 20px;
}
</style>
