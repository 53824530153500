
import { useMainStore } from '@/store'
import anims from "@/utils/animFunctions";

export default defineNuxtPlugin((nuxtApp) => {
    const router = useRouter();
    const mainStore = useMainStore()

    const initAnim = nuxtApp.$initAnim

    router.beforeEach((to, from) => {
        if (import.meta.client) {
            if (mainStore.isMobile) {
                setTimeout(() => {
                    window.scrollTo(0, 0);
                }, 500)
            }
        }
        setTimeout(() => {
            mainStore.changeFooter(false);
        }, 100)
    });

    router.afterEach((to, from) => {
        // console.log(from)
        setTimeout(() => {
            mainStore.changeFooter(true);
        }, 1000)

        if (import.meta.client) {

            setTimeout(() => {
                if (from.path === "/") anims.animateSubNavbar()
            }, 700)

            if (to.path === from.path) {
                setTimeout(() => {
                    initAnim()
                }, 2000)
            } else {
                setTimeout(() => {
                    // console.log("change router")
                    initAnim()
                    anims.animateHeader();
                }, 1000)

            }
        }
    });

});


