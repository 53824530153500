
<script setup>
import copyright from "@/utils/copyright.js";
import { useMainStore } from "@/store";
import { useCategsStore } from "@/store/categs";
import EmailSubscribe from "@/components/layout/Other/Footer/EmailSubscribe.vue";
import DefaultFooterList from "./DefaultFooterList.vue";

const store = useMainStore();
const categs = useCategsStore();

const { discover, company, contact, legal, account } = categs.categs;

const wealth = ref([
  {
    title: "Gold_and_Silver",
    path: "/products/gold-silver",
    exist: true,
  },
  {
    title: "Benefits",
    path: "/products/benefits",
    exist: true,
  },
  {
    title: "Vault_and_Trust",
    path: "/products/vault-trust",
    exist: true,
  },
  {
    title: "Advisory",
    path: "/products/advisory",
    exist: true,
  },
]);

const getCopyright = () => {
  return copyright();
};

const redirect = (path) => {
  window.open(path);
};
</script>

<template>
  <div class="footer">
    <div class="footer__emailSubscribe">
      <EmailSubscribe />
    </div>
    <div class="footer__blockDiscover" v-if="!(store.isMobile & store.isMenu)">
      <DefaultFooterList
        title="Discover"
        :content="discover"
      />
    </div>
    <div class="footer__blockWealth" v-if="!(store.isMobile & store.isMenu)">
      <DefaultFooterList
        title="Wealth protection"
        :content="wealth"
      />
    </div>
    <div class="footer__blockAbout" v-if="!(store.isMobile & store.isMenu)">
      <DefaultFooterList title="About" :content="company" />
    </div>
    <div class="footer__blockContact"
      v-if="!(store.isMobile & store.isMenu)"
    >
      <DefaultFooterList
        title="Contact"
        :content="contact"
      />
    </div>
    <div class="footer__blockLegal"
      v-if="!(store.isMobile & store.isMenu)"
    >
      <DefaultFooterList
        title="Legal"
        :content="legal"
      />
    </div>
    <div class="footer__blockMyaccount"
      v-if="!(store.isMobile & store.isMenu)"
    >
      <DefaultFooterList
        title="My Account"
        :content="account"
      />
    </div>
    <div class="footer__appStore">
      <NuxtImg
        class="footer__appStore_img"
        src="/image/footer/appstore-logo.png"
        format="webp"
        alt="appstore logo"
        @click="
          redirect('https://apps.apple.com/us/app/goldensuisse/id1236821991')
        "
      />
    </div>
    <div class="footer__social">
      <NuxtImg
        class="footer__social_img"
        src="/image/footer/google-play-logo.png"
        alt="google play logo"
        format="webp"
        @click="
          redirect(
            'https://play.google.com/store/apps/details?id=com.golden.coin&hl=en_US'
          )
        "
      />
    </div>
    <div class="footer__info">
      {{ getCopyright() + $t("copyright")}}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  padding: 100px 60px 40px 60px;
  display: grid;
  grid-template-areas:
    "emailSubscribe emailSubscribe . . discover wealth about contact"
    ". . . . . . legal myaccount"
    "appStore social info info info info . .";
  grid-template-columns: repeat(8, 12.5%);
  height: max-content;

  &__emailSubscribe {
    grid-area: emailSubscribe;
  }

  &__blockDiscover {
    grid-area: discover;
  }
  &__blockWealth {
    grid-area: wealth;
  }
  &__blockAbout {
    grid-area: about;
  }
  &__blockContact {
    grid-area: contact;
  }
  &__blockLegal {
    grid-area: legal;
  }
  &__blockMyaccount {
    grid-area: myaccount;
  }

  &__appStore {
    grid-area: appStore;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
    margin-top: 40px;

    &_img {
      width: 80%;
      cursor: pointer;
    }
  }

  &__social {
    grid-area: social;
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin-top: 40px;

    &_img {
      width: 80%;
      cursor: pointer;
    }
  }

  &__info {
    grid-area: info;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;

    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -0.28px;
    color: $darkGrey;
  }

  .footerList {
    &__title {
      line-height: 33px;
    }

    &__list {
      padding: 0px;
    }
  }
}
@media (max-width: 1599px) {
  .footer {
    padding: 80px 60px 40px 60px;
    grid-template-areas:
      "emailSubscribe emailSubscribe . discover wealth"
      ". . . about contact"
      ". . . legal myaccount"
      "appStore social info info .";
    grid-template-columns: 15% 15% 20% 25% 25%;

    &__appStore {
      margin-top: 30px;
      &_img {
        width: 70%;
      }
    }
    &__social {
      margin-top: 30px;
      &_img {
        width: 70%;
      }
    }
    &__info {
      margin-top: 30px;
    }
  }
}

@media (max-width: 1279px) {
  .footer {
    padding: 80px 45px 20px 45px;
    grid-template-areas:
      "emailSubscribe emailSubscribe . discover wealth"
      ". . . about contact"
      ". . . legal myaccount"
      ". appStore appStore social ."
      ". info info info .";
    grid-template-columns: 25% 9% 16% 25% 25%;

    &__appStore {
      margin-top: 60px;
      padding-right: 5.98%;
      &_img {
        width: 142px;
      }
    }
    &__social {
      margin-top: 60px;
      padding-left: 13.76%;

      &_img {
        width: 142px;
      }
    }
    &__info {
      line-height: 50px;
      margin-top: 60px;
    }
  }
}

@media (max-width: 1023px) {
  .footer {
    padding: 80px 45px 20px 45px;
    grid-template-areas:
      "emailSubscribe emailSubscribe"
      "discover ."
      "wealth about"
      "contact ."
      "legal myaccount"
      "appStore social"
      "info info";
    grid-template-columns: 50% 50%;

    &__blockDiscover {
      margin-top: 60px;
    }
    &__appStore {
      margin-top: 60px;
    }
    &__social {
      margin-top: 60px;
    }
    &__info {
      margin-top: 40px;
    }
  }
}

@media (max-width: 767px) {
  .footer {
    padding: 60px 20px 0px 20px;
    grid-template-areas:
      "emailSubscribe emailSubscribe"
      "discover wealth"
      "about contact"
      "legal myaccount"
      "appStore social"
      "info info";
    grid-template-columns: 50% 50%;

    &__blockWealth {
      margin-top: 60px;
    }
    &__info {
      margin-top: 20px;
    }

    &__appStore {
      margin-top: 10px;
    }

    &__social {
      margin-top: 10px;
    }
  }
}
</style>
