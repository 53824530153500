<script setup>
import { useRoute } from "vue-router";
import { useMainStore } from "@/store";
import { useCategsStore } from "@/store/categs";
import anims from "@/utils/animFunctions";
import DefaultFooter from "~/components/layout/Default/Footer/DefaultFooter.vue";
import OtherHeader from "@/components/layout/Other/Header/OtherHeader.vue";
import OtherMenu from "@/components/layout/Other/Menu/OtherMenu.vue";
import Scrollbar from "smooth-scrollbar";

const store = useMainStore();
const storeCategs = useCategsStore();
const route = useRoute();
const error = useError();

const initAnim = useNuxtApp().$initAnim;

const isMenu = ref(false);
const openedMenu = ref(false);
const linkList = ref([]);

const openMenu = () => {
  setTimeout(() => {
    isMenu.value = true;
  }, 100);
  store.changeFooter(false);
  store.setMenuStatus(true);

  setTimeout(() => {
    openedMenu.value = true;
  }, 500);
  if (store.isMobile) {
    store.setScrollHeight(window.scrollY);
  }
  anims.destroySubNavbar();
};

const closeMenu = () => {
  openedMenu.value = false;
  setTimeout(() => {
    isMenu.value = false;
    store.setMenuStatus(false);
  }, 500);
  store.changeFooter(true);
  anims.animateSubNavbar();
  setTimeout(() => {
    initAnim();
  }, 1500);
};

const handleError = () => {
  clearError({ redirect: "/" });
};

onMounted(() => {
  const pageEl = document.querySelector(".error-page");

  if (!store.isMobile) {
    Scrollbar.init(pageEl, { damping: 0.07 });
  }
  updateLinkList();
  setTimeout(() => {
    initAnim();
  }, 1000);
  setTimeout(() => {
    anims.animateSubNavbar();
  }, 1500);
  setTimeout(() => {
    anims.animateHeader();
  }, 2500);
});

const updateLinkList = () => {
  const slug = route.path.split("/")[1];
  if (storeCategs.getOnlyMenusPages().hasOwnProperty(slug)) {
    linkList.value = storeCategs.getOnlyMenusPages()[slug];
  } else {
    linkList.value = [];
  }
};

watch(
  () => route.fullPath,
  (newPath) => {
    if (isMenu.value) {
      isMenu.value = false;
      openedMenu.value = false;
      store.setMenuStatus(false);

      linkList.value = [];
    }
    setTimeout(() => {
      if (newPath !== "/") {
        updateLinkList();
      } else {
        linkList.value = [];
      }
    }, 500);
  }
);

useHead({
  title: error.value.message,
});
</script>

<template>
  <div>
    <Transition name="fadeMenu">
      <OtherMenu v-show="openedMenu" style="padding-top: 100px" />
    </Transition>

    <header style="overflow: hidden">
      <OtherHeader
        style="position: fixed; top: 0; width: 100%; z-index: 3"
        :openedMenu="openedMenu"
        :isMenu="isMenu"
        :linkList="linkList"
        @open-menu="openMenu"
        @close-menu="closeMenu"
      />
    </header>
    <Transition name="fade">
      <div v-show="!isMenu" class="error-page">
        <main>
          <h2>{{ error.statusCode }}</h2>
          <h2>{{ error.message }}</h2>
          <button @click="handleError">Clear errors</button>
        </main>
        <Transition name="fade">
          <DefaultFooter />
        </Transition>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
.error-page {
  height: 100vh;
  main {
    padding: 295px 0 0 0;
    background: $white;
    min-height: 60vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h2 {
      font-size: 28px;
      margin-bottom: 20px;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 14px;
      line-height: 143%;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      width: 295px;
      height: 60px;
      color: $black;
      overflow: hidden;
      position: relative;
      transition: all linear 0.5s;
      -webkit-transition: all linear 0.5s;
      cursor: pointer;
      background: $white;
      margin-top: 20px;
    }
    button:hover {
      background: $black;
      color: $white;
      transition: all linear 0.5s;
      -webkit-transition: all linear 0.5s;
    }
  }
}
</style>