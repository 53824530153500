// plugins/appMixins.js
import { defineNuxtPlugin } from '#app'
import { useMainStore } from '@/store'
import anims from '@/utils/animFunctions'
import Scrollbar from 'smooth-scrollbar';

export default defineNuxtPlugin(nuxtApp => {

  const mainStore = useMainStore()

  const LCP_SUB_PARTS = [
    'Time to first byte',
    'Resource load delay',
    'Resource load duration',
    'Element render delay',
  ];

  nuxtApp.hook('app:created', (nuxtApp) => {

    isMobile(mainStore.platform)

    nextTick(() => {

      if (import.meta.client) {
        window.innerWidth < 1024 ? mainStore.setIsMobile(true) : mainStore.setIsMobile(false)
        mainStore.setMainLoaderActive(true)
        mainStore.setWindowLoaded()
        mainStore.updateLoadingStatus(false)
      }
    })
  })

  nuxtApp.hook('app:mounted', () => {

    // new PerformanceObserver((list) => {
    //   const lcpEntry = list.getEntries().at(-1);
    //   const navEntry = performance.getEntriesByType('navigation')[0];
    //   const lcpResEntry = performance
    //     .getEntriesByType('resource')
    //     .filter((e) => e.name === lcpEntry.url)[0];
    
    //   // Ignore LCP entries that aren't images to reduce DevTools noise.
    //   // Comment this line out if you want to include text entries.
    //   if (!lcpEntry.url) return;
    
    //   // Compute the start and end times of each LCP sub-part.
    //   // WARNING! If your LCP resource is loaded cross-origin, make sure to add
    //   // the `Timing-Allow-Origin` (TAO) header to get the most accurate results.
    //   const ttfb = navEntry.responseStart;
    //   const lcpRequestStart = Math.max(
    //     ttfb,
    //     // Prefer `requestStart` (if TOA is set), otherwise use `startTime`.
    //     lcpResEntry ? lcpResEntry.requestStart || lcpResEntry.startTime : 0
    //   );
    //   const lcpResponseEnd = Math.max(
    //     lcpRequestStart,
    //     lcpResEntry ? lcpResEntry.responseEnd : 0
    //   );
    //   const lcpRenderTime = Math.max(
    //     lcpResponseEnd,
    //     // Use LCP startTime (the final LCP time) because there are sometimes
    //     // slight differences between loadTime/renderTime and startTime
    //     // due to rounding precision.
    //     lcpEntry ? lcpEntry.startTime : 0
    //   );
    
    //   // Clear previous measures before making new ones.
    //   // Note: due to a bug, this doesn't work in Chrome DevTools.
    //   LCP_SUB_PARTS.forEach((part) => performance.clearMeasures(part));
    
    //   // Create measures for each LCP sub-part for easier
    //   // visualization in the Chrome DevTools Performance panel.
    //   const lcpSubPartMeasures = [
    //     performance.measure(LCP_SUB_PARTS[0], {
    //       start: 0,
    //       end: ttfb,
    //     }),
    //     performance.measure(LCP_SUB_PARTS[1], {
    //       start: ttfb,
    //       end: lcpRequestStart,
    //     }),
    //     performance.measure(LCP_SUB_PARTS[2], {
    //       start: lcpRequestStart,
    //       end: lcpResponseEnd,
    //     }),
    //     performance.measure(LCP_SUB_PARTS[3], {
    //       start: lcpResponseEnd,
    //       end: lcpRenderTime,
    //     }),
    //   ];
    
    //   // Log helpful debug information to the console.
    //   console.log('LCP value: ', lcpRenderTime);
    //   console.log('LCP element: ', lcpEntry.element, lcpEntry.url);
    //   console.table(
    //     lcpSubPartMeasures.map((measure) => ({
    //       'LCP sub-part': measure.name,
    //       'Time (ms)': measure.duration,
    //       '% of LCP': `${
    //         Math.round((1000 * measure.duration) / lcpRenderTime) / 10
    //       }%`,
    //     }))
    //   );
    // }).observe({type: 'largest-contentful-paint', buffered: true});

    setTimeout(() => {
      mainStore.setMainLoaderActiveClass(true)
      anims.animContentLoader()
    }, 300)

    setTimeout(() => {
      mainStore.setMainLoaderActiveClass(false)
      mainStore.setMainLoaderActive(false)
    }, 2300)

    document.addEventListener("visibilitychange", (e) => {
      // console.log(document.visibilityState, "6666"); 
      if (document.visibilityState === "visible") {
        setTimeout(() => {
          initAnim()
        }, 1000)
      }
    });

    window.addEventListener('orientationchange', () => {

      if (window.innerWidth < 1024) {
        // console.log('hander anims')
      }
      if (navigator.userAgentData) {
        navigator.userAgentData.getHighEntropyValues(["platform"]).then((ua) => {
          mainStore.setPlatform(ua.platform)
          isMobile(ua.platform)
          if (/^iPhone|^iPad|^iPod/.test(ua.platform)) {
            window.location.reload(true)
          }
        });
      }
    })

    window.addEventListener('resize', () => {
      if (!mainStore.initResize && window.innerWidth >= 1024) {
        mainStore.setInitResize(true)
        if (mainStore.isMobile) mainStore.setAppFlag(true)
      }

      if (mainStore.appFlag && window.innerWidth >= 1024 && mainStore.initResize) {
        // console.log('landscape')
        animateHeader()
        animateSubNavbar()
        initSmoothscroll()
        initlanguageEl()
        setTimeout(() => {
          initAnim()
        }, 100)
        mainStore.setAppFlag(false)
        isMobile(mainStore.platform)
        mainStore.setIsMobile(false)
      }
      if (window.innerWidth < 1024 && mainStore.initResize && !mainStore.appFlag) {
        // console.log('portrait')
        mainStore.setAppFlag(true)
        isMobile(mainStore.platform)
        mainStore.setIsMobile(true)
        destroySmoothscroll()
        destroylanguageEl()
        animDoneReset()
        destroySubNavbar()
      }
    })
  })

  function animDoneReset() {
    const cont = document.querySelector('.inner-page') || document.querySelector('.white-page')
    if (!cont) return
    const animElems = cont.querySelectorAll('[data-anim]')
    animElems.forEach(item => {
      item.removeAttribute('data-anim-done')
    })
  }

  function initSmoothscroll() {
    const cont = document.querySelector('.inner-page') || document.querySelector('.white-page')
    if (!cont) return
    Scrollbar.init(cont, {
      damping: 0.07,
    })
  }
  function initlanguageEl() {
    const languageEl = document.querySelector(".languageScrollbar .language-content");
    if (!languageEl) return
    Scrollbar.init(languageEl, { damping: 0.07 });
  }

  function destroySmoothscroll() {
    const cont = document.querySelector('.inner-page') || document.querySelector('.white-page')
    if (!cont) return
    Scrollbar.destroy(cont)
  }

  function destroylanguageEl() {
    const languageEl = document.querySelector(".language-content");
    if (!languageEl) return
    Scrollbar.destroy(languageEl);
  }

  function animateHeader() {
    anims.animateHeader()
  }

  function destroySubNavbar() {
    anims.destroySubNavbar()
  }

  function animateSubNavbar() {
    anims.animateSubNavbar()
  }

  function isMobile(platform) {
    if (navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(platform)) {

      mainStore.setIsMobile(true);
    }
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {

      mainStore.setIsMobile(true);
    } else {
      // console.log(platform, '444444')
      mainStore.setIsMobile(false)
    }
  }

  function initAnim() {
    const animElems = document.querySelectorAll('[data-anim]');
    const arrayAnims = Object.getOwnPropertyNames(anims);
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const animEl = entry.target;
          const animFunction = animEl.getAttribute('data-anim');
          const animDelay = Number(animEl.getAttribute('data-anim-delay')) || mainStore.totalDelay;
          const animDuration = Number(animEl.getAttribute('data-anim-duration')) || mainStore.totalDuration;

          if (arrayAnims.includes(animFunction)) {
            anims[animFunction](animEl, animDelay, animDuration);
            animEl.setAttribute('data-anim-done', 'true');
            animEl.classList.add('animated');
            observer.unobserve(animEl);
          }
        }
      });
    });

    // function initAnim() {
    //   const arrayAnims = Object.getOwnPropertyNames(anims)
    //   const thisEl = document.querySelector('.inner-page') || document.querySelector('.white-page')
    //   if (!thisEl) return
    //   const animElems = thisEl.querySelectorAll('[data-anim]')
    //   let animFunction, animDelay, animDuration

    //   eachAnims(animElems, thisEl)
    //   if (Scrollbar.get(thisEl)) {
    //     Scrollbar.get(thisEl).addListener(() => {
    //       eachAnims(animElems, thisEl)
    //     })
    //   }

    //   function eachAnims(animElems, scrollContainer) {

    //     animElems.forEach(animEl => {

    //       if (Scrollbar.get(scrollContainer)?.isVisible(animEl) && !animEl.hasAttribute('data-anim-done') && !animEl.classList.contains('animated')) {

    //         animFunction = animEl.getAttribute('data-anim')
    //         animDelay = Number(animEl.getAttribute('data-anim-delay')) || mainStore.totalDelay
    //         animDuration = Number(animEl.getAttribute('data-anim-duration')) || mainStore.totalDuration
    //         if (arrayAnims.includes(animFunction)) {
    //           anims[animFunction](animEl, animDelay, animDuration)
    //         }
    //       }
    //     })
    //   }
    // }

    animElems.forEach(animEl => observer.observe(animEl));
  }


  nuxtApp.provide("initAnim", initAnim)
  nuxtApp.provide("destroySmoothscroll", destroySmoothscroll)
})