
import * as ipxStaticRuntime$d5mj7h_MyinYMl5CFlw_45cHudjMDhTLTDNYy8RL9V3kw from '/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/node_modules/@nuxt/image/dist/runtime/providers/ipxStatic'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 768,
    "md": 1024,
    "lg": 1280,
    "xl": 1600,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "default": {
      "modifiers": {
        "format": "webp",
        "quality": 80
      }
    }
  },
  "provider": "ipxStatic",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "avif",
    "webp"
  ]
}

imageOptions.providers = {
  ['ipxStatic']: { provider: ipxStaticRuntime$d5mj7h_MyinYMl5CFlw_45cHudjMDhTLTDNYy8RL9V3kw, defaults: {} }
}
        