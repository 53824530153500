
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as authorizem79FystRUylPghsVPU0d4qg0Mx2tnzMmGNMlNqTq3QIMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/account/authorize.vue?macro=true";
import { default as code_45confirmIbqroselKb9EOHVlpN0sL5d6Ot1Ejjam23cwHgxHWMEMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/account/code-confirm.vue?macro=true";
import { default as downloadve6IGIB40mfpGdB27Hx2RFvYDD1R91WyWJNgaP8X_KUMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/account/download.vue?macro=true";
import { default as forgot4wxfEqnUMLzptk8bqEUDpIhGQ__45_yMB9raEyTEcyGGsMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/account/forgot.vue?macro=true";
import { default as indexc82DQzAF8M_45FO9bVEkNJsov3j9RoljcCY5nKVnZEWcsMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/account/index.vue?macro=true";
import { default as registeroWJ8pztVsFi8PeBHS7qeIP5AQrignLfhYVD84_KB_1cMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/account/register.vue?macro=true";
import { default as reset_45passwordIuTX0QHg5Dfswe8ak9WAVLcPHgYLN6EdSA_455zPkfFVUMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/account/reset-password.vue?macro=true";
import { default as success4C_lBAhC2gHhrBwsqNjzMEL_45deDGq8obaqOiD0_45NtB8Meta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/account/success.vue?macro=true";
import { default as indexb44ZauathkVGsqQKIQXMpAjE_45m4Zn0M2yu83l33ZWDMMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/awards/index.vue?macro=true";
import { default as bullion_45dealerswEcP_450II36WvQQksG9gXiDQ7mlCM6NHUbP5ddjh1_lAMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/become-partner/bullion-dealers.vue?macro=true";
import { default as form8Sl3wFEXTCaeL_45mts_455ydnvR9Tkcz4fmjpSnAXULlRoMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/become-partner/form.vue?macro=true";
import { default as indexbFAuCFQuff0QLma1polMnNtxlilTmDdmU2MjZetdF6kMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/become-partner/index.vue?macro=true";
import { default as private_45banksTQiaTum8rJ_AFdfInCCIqvSshX827fNf8DGvWvjyoP0Meta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/become-partner/private-banks.vue?macro=true";
import { default as refineriesQ2_twCa2MWtPpQOaqznqe22fJarlGd5vWjL6dCvlOsIMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/become-partner/refineries.vue?macro=true";
import { default as wealth_45managerstvTPCX0ZpiI8DtNkLzpRluYbiUHsxwfkU33sy1CDpI4Meta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/become-partner/wealth-managers.vue?macro=true";
import { default as indexd3DM1ZRBhuHmMTlHpZO9Sfzdg1MQtQCQ_45G6b8CktDj0Meta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/governance/index.vue?macro=true";
import { default as index52wOdK98jIpXlxXmJZn4qk81WX3oLmDtJiz98jkeUkAMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/index.vue?macro=true";
import { default as indexktpnsCGhsspi3vs_45nmKPkXy2XvtO9JNpWicYiBC3oZ0Meta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/rates/index.vue?macro=true";
import { default as approachCOts8wnlV10GIPWfySEaP5sbqe_q_VJF5H3Rr9nEZosMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/sustainability/approach.vue?macro=true";
import { default as challenge1t6lUQuSO99IMV8hV7OTn6CjFa5Mm8E1ngsUTArFtZ4Meta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/sustainability/challenge.vue?macro=true";
import { default as children33o788f9G15v7_45Mvduwad5g43dpWXoS0wMajH1biqC8Meta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/sustainability/children.vue?macro=true";
import { default as indexUd_45VJPmG9xJn37yofqVvMR05PwZSAk9wsbloE85_l1oMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/sustainability/index.vue?macro=true";
import { default as missionNSul2HZxjmHa3V7HhtZFYELdyyi7mdg4f87pi7cWDSwMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/sustainability/mission.vue?macro=true";
import { default as indexecPhdQk9bkbwp4dOjRxBl17Rj_45WmG45pqoka9e7kXuEMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/contact/index.vue?macro=true";
import { default as supportJmV0GHjf11JkT8oS1EIFS6p4knHfAdggo06a6vwEOhgMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/contact/support.vue?macro=true";
import { default as indexUj7HGgNFKXeOmjwvNsW1koJEfGxrxOmTEV02bRGKgEwMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/index.vue?macro=true";
import { default as information_nXheyYNCbjoW6XFBTKiLVms_45lcPzFBWmv6gTk89OlwMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/legal/information.vue?macro=true";
import { default as privacy1v7BeA_CKYFdPj9O5DAH0P1eL_45RYKY1EfrivDB_Su3kMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/legal/privacy.vue?macro=true";
import { default as vqf_45membership_sxjp_nU3xijzZa8dKdOA3H9U0LmBhV0RMA_45yS9jWAsMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/legal/vqf-membership.vue?macro=true";
import { default as indexrFqVDZvYVhT1JqI3eEMKwokldylLwIkiPTrg57OMCTEMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/privacy-policy/index.vue?macro=true";
import { default as indexAsxDFWxJUmbaF9qD9AygyeQQqEQ5Dfv_45R3FJTHnYPPQMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/advisory/index.vue?macro=true";
import { default as currency_45riskLC_NLfosRmqMaoHSuH_450b57Yy6SH6yTwRGBmSNxb290Meta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/benefits/currency-risk.vue?macro=true";
import { default as data_45privacyrP5DDwCQz9_WDnVEdjlMote9dWQzocZorWVAdAKFWDEMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/benefits/data-privacy.vue?macro=true";
import { default as data_45securityZyOPZIHRvP72HkcATLmArwxYH7HKq7EEZqB09mypU2EMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/benefits/data-security.vue?macro=true";
import { default as deposit_45riskC1JGqytXEyILS_45SNn4URT_OAcw7RPpRqpmUtY6oRKgAMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/benefits/deposit-risk.vue?macro=true";
import { default as financial_45riskGz01kokQiiUUPTfZGh8gQWnHd_4M4OiGtQI6Gs9pujAMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/benefits/financial-risk.vue?macro=true";
import { default as geopolitical_45riskxjTFexvBrQ18Cy0_451px_2NijAi1BUI5LZ0gCgo_kb34Meta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/benefits/geopolitical-risk.vue?macro=true";
import { default as indexmhQ5cg6SBv24oXXtivVGzZGm2Unnn8N1y_lEBaFkSGkMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/benefits/index.vue?macro=true";
import { default as risk_45and_45return4CM5A1w_xGjOMg_KWxWVlMIETP09xRz6jxq22cDp6aUMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/benefits/risk-and-return.vue?macro=true";
import { default as tax_45benefitshusx5LJTGOlDcsXSjpgm9JAFD_dygh2OvTAoVUHlbmMMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/benefits/tax-benefits.vue?macro=true";
import { default as clubnewprwVh4GJ3zcXXGMN0DJzMQ6znZWYpux4FJ24w6sYMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/club.vue?macro=true";
import { default as fast_45tracknibZFGS9T_7h5VltauRkGknZyWEhLqiFlGPyEiG8qAoMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/fast-track.vue?macro=true";
import { default as global_45roamingmuwAsF9CfYGmffCF4zGObNS_45mIhSs77h9sOYoLU4SakMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/global-roaming.vue?macro=true";
import { default as gold_45reserve_45cardPGuH_ebqgtsebEZJeRI0FTcz1LElAqeMsXzNKIQYkG0Meta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/gold-reserve-card.vue?macro=true";
import { default as index6DUR3p_45zRGynLi0BCeeqi4lqLomRCMrpVkOMMqG81QMMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/index.vue?macro=true";
import { default as interestuPTUW9gYHJx11Jd49vOH0dXxVNadEEDX2mdua6Mz_tsMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/interest.vue?macro=true";
import { default as lifestyle_45and_45travel_45managerloDPkgVTp5Jwd5_456Xr_45lIXpEQ6RnWes_5O3M3AaLJ24Meta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/lifestyle-and-travel-manager.vue?macro=true";
import { default as mastercard_45world_45elitem3QmHHMSyAktCXNG4uYQj_45XqXgSwJgHzibPxe3Ilg1cMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/mastercard-world-elite.vue?macro=true";
import { default as priority_45passZUCEAMnCp8o6SNUIgGMSj0twGN3ByycJRSaxWAamBx4Meta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/priority-pass.vue?macro=true";
import { default as private_45transactionsADsp9z7rmKbefkoEtOVJLiTq_p9YKMEsx40_rItEv9QMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/private-transactions.vue?macro=true";
import { default as spending_45abroadaSjaV86KDGmeEkI_s_e2BkobHnHV_2z70qKr146XpF0Meta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/spending-abroad.vue?macro=true";
import { default as unlimitedBV6oazxgL7GlnNRYmFDCVgH4d8dEfDh8MU37dgMoLa8Meta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/unlimited.vue?macro=true";
import { default as coins_45vs_45barsMi2MDICyPJJZk2vg31KlXnf6zdN5hTU_sE1LiVuvPjAMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/gold-silver/coins-vs-bars.vue?macro=true";
import { default as hard_45versus_45softPiqFr5T0rzGZheJCttiXMp1q8lfDwPV2_451oCAUvXIK0Meta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/gold-silver/hard-versus-soft.vue?macro=true";
import { default as history_45of_45gold9Q64Hj_45JvPfeoGgWWwBBqbftKXmIv1Zlo6a83FEJdbwMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/gold-silver/history-of-gold.vue?macro=true";
import { default as index7cbcOQHRf2u5BsXxgyuHA8mkQ0CJmfH22CL6RkQx85MMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/gold-silver/index.vue?macro=true";
import { default as invest403kFJlXuxIq_TaBz5ePsf2JNlgmVxQgz3Thii_ly58Meta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/gold-silver/invest.vue?macro=true";
import { default as investment_45vs_45collectorL6sXNq6JJYODO5ikL6ZDcCz_45QfnMBiG85_eHFPXWn7gMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/gold-silver/investment-vs-collector.vue?macro=true";
import { default as legal_45tenderabeGbFQJr6qinG782nBxplzHK5_ueH7RgNeYSCBb1WkMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/gold-silver/legal-tender.vue?macro=true";
import { default as one_45oz_45gold1rhWiWFqxmupnIr_459zDlk1CvJ2C2ncjzszII_45gtoVfkMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/gold-silver/one-oz-gold.vue?macro=true";
import { default as one_45oz_45silverMbGGOsrVVhhCqgUqtlyMyRm8MRoub4eeXY2fXzC7KxkMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/gold-silver/one-oz-silver.vue?macro=true";
import { default as types_45of_45investmentj0Orz7SRmaPk2tBEb_45PrzEcJihtxDMLIGUDgV9i2jg0Meta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/gold-silver/types-of-investment.vue?macro=true";
import { default as bullion_45transfersdNWl_O28d_459mc5LxxIp4_45JZkAYg_r646iKP_45IFMMRGgMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/payments/bullion-transfers.vue?macro=true";
import { default as bullion_45vault_45holdingsYHic_P_PSj1rADCHmXM825WTc95jgZBmUkQN_xAFen4Meta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/payments/bullion-vault-holdings.vue?macro=true";
import { default as indexblb2JzHMxDyLRRvsZlhi2FWJlr67C1S_x8dec227l80Meta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/payments/index.vue?macro=true";
import { default as open_45banking7IxVE11OeK9GAiOCgPsWG4JEaIzYvPbZ1pNjbHcozisMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/payments/open-banking.vue?macro=true";
import { default as private_45card_451f98Hkwt9j1_tDQx_FSeLyU54AGicyqnENVmhuUbhwMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/payments/private-card.vue?macro=true";
import { default as auditsFFhb_M2bLFd7qIohTjbar__45vNinwCaA310A4eV0mh1IMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/vault-trust/audits.vue?macro=true";
import { default as bullion_45accessSMTHAv1L95Hm9POx_J8u77bPjz7sT03ui_45Tn9Bi1alsMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/vault-trust/bullion-access.vue?macro=true";
import { default as external_45bullion_45depositsclu_45ITTliigLpp6d9DqNQG0BojODjMcrJ7x_S44iwMkMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/vault-trust/external-bullion-deposits.vue?macro=true";
import { default as index14InpaRHs4WQdk5RCh1IfXkpQ7nd2ie50G1t0203d2YMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/vault-trust/index.vue?macro=true";
import { default as insurancerWLa6Smm1V_0BvV_0fgL1DPpXBqPNOXK0kvs2Qwkc9gMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/vault-trust/insurance.vue?macro=true";
import { default as vaultsblba3L1DDnn8LQtP_gFlkczIl0KCBMFE_tToDBLQWJQMeta } from "/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/vault-trust/vaults.vue?macro=true";
export default [
  {
    name: "account-authorize",
    path: "/account/authorize",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/account/authorize.vue")
  },
  {
    name: "account-code-confirm",
    path: "/account/code-confirm",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/account/code-confirm.vue")
  },
  {
    name: "account-download",
    path: "/account/download",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/account/download.vue")
  },
  {
    name: "account-forgot",
    path: "/account/forgot",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/account/forgot.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/account/index.vue")
  },
  {
    name: "account-register",
    path: "/account/register",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/account/register.vue")
  },
  {
    name: "account-reset-password",
    path: "/account/reset-password",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/account/reset-password.vue")
  },
  {
    name: "account-success",
    path: "/account/success",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/account/success.vue")
  },
  {
    name: "company-awards",
    path: "/company/awards",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/awards/index.vue")
  },
  {
    name: "company-become-partner-bullion-dealers",
    path: "/company/become-partner/bullion-dealers",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/become-partner/bullion-dealers.vue")
  },
  {
    name: "company-become-partner-form",
    path: "/company/become-partner/form",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/become-partner/form.vue")
  },
  {
    name: "company-become-partner",
    path: "/company/become-partner",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/become-partner/index.vue")
  },
  {
    name: "company-become-partner-private-banks",
    path: "/company/become-partner/private-banks",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/become-partner/private-banks.vue")
  },
  {
    name: "company-become-partner-refineries",
    path: "/company/become-partner/refineries",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/become-partner/refineries.vue")
  },
  {
    name: "company-become-partner-wealth-managers",
    path: "/company/become-partner/wealth-managers",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/become-partner/wealth-managers.vue")
  },
  {
    name: "company-governance",
    path: "/company/governance",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/governance/index.vue")
  },
  {
    name: "company",
    path: "/company",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/index.vue")
  },
  {
    name: "company-rates",
    path: "/company/rates",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/rates/index.vue")
  },
  {
    name: "company-sustainability-approach",
    path: "/company/sustainability/approach",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/sustainability/approach.vue")
  },
  {
    name: "company-sustainability-challenge",
    path: "/company/sustainability/challenge",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/sustainability/challenge.vue")
  },
  {
    name: "company-sustainability-children",
    path: "/company/sustainability/children",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/sustainability/children.vue")
  },
  {
    name: "company-sustainability",
    path: "/company/sustainability",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/sustainability/index.vue")
  },
  {
    name: "company-sustainability-mission",
    path: "/company/sustainability/mission",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/company/sustainability/mission.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/contact/index.vue")
  },
  {
    name: "contact-support",
    path: "/contact/support",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/contact/support.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/index.vue")
  },
  {
    name: "legal-information",
    path: "/legal/information",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/legal/information.vue")
  },
  {
    name: "legal-privacy",
    path: "/legal/privacy",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/legal/privacy.vue")
  },
  {
    name: "legal-vqf-membership",
    path: "/legal/vqf-membership",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/legal/vqf-membership.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/privacy-policy/index.vue")
  },
  {
    name: "products-advisory",
    path: "/products/advisory",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/advisory/index.vue")
  },
  {
    name: "products-benefits-currency-risk",
    path: "/products/benefits/currency-risk",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/benefits/currency-risk.vue")
  },
  {
    name: "products-benefits-data-privacy",
    path: "/products/benefits/data-privacy",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/benefits/data-privacy.vue")
  },
  {
    name: "products-benefits-data-security",
    path: "/products/benefits/data-security",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/benefits/data-security.vue")
  },
  {
    name: "products-benefits-deposit-risk",
    path: "/products/benefits/deposit-risk",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/benefits/deposit-risk.vue")
  },
  {
    name: "products-benefits-financial-risk",
    path: "/products/benefits/financial-risk",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/benefits/financial-risk.vue")
  },
  {
    name: "products-benefits-geopolitical-risk",
    path: "/products/benefits/geopolitical-risk",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/benefits/geopolitical-risk.vue")
  },
  {
    name: "products-benefits",
    path: "/products/benefits",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/benefits/index.vue")
  },
  {
    name: "products-benefits-risk-and-return",
    path: "/products/benefits/risk-and-return",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/benefits/risk-and-return.vue")
  },
  {
    name: "products-benefits-tax-benefits",
    path: "/products/benefits/tax-benefits",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/benefits/tax-benefits.vue")
  },
  {
    name: "products-card-club",
    path: "/products/card/club",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/club.vue")
  },
  {
    name: "products-card-fast-track",
    path: "/products/card/fast-track",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/fast-track.vue")
  },
  {
    name: "products-card-global-roaming",
    path: "/products/card/global-roaming",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/global-roaming.vue")
  },
  {
    name: "products-card-gold-reserve-card",
    path: "/products/card/gold-reserve-card",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/gold-reserve-card.vue")
  },
  {
    name: "products-card",
    path: "/products/card",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/index.vue")
  },
  {
    name: "products-card-interest",
    path: "/products/card/interest",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/interest.vue")
  },
  {
    name: "products-card-lifestyle-and-travel-manager",
    path: "/products/card/lifestyle-and-travel-manager",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/lifestyle-and-travel-manager.vue")
  },
  {
    name: "products-card-mastercard-world-elite",
    path: "/products/card/mastercard-world-elite",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/mastercard-world-elite.vue")
  },
  {
    name: "products-card-priority-pass",
    path: "/products/card/priority-pass",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/priority-pass.vue")
  },
  {
    name: "products-card-private-transactions",
    path: "/products/card/private-transactions",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/private-transactions.vue")
  },
  {
    name: "products-card-spending-abroad",
    path: "/products/card/spending-abroad",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/spending-abroad.vue")
  },
  {
    name: "products-card-unlimited",
    path: "/products/card/unlimited",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/card/unlimited.vue")
  },
  {
    name: "products-gold-silver-coins-vs-bars",
    path: "/products/gold-silver/coins-vs-bars",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/gold-silver/coins-vs-bars.vue")
  },
  {
    name: "products-gold-silver-hard-versus-soft",
    path: "/products/gold-silver/hard-versus-soft",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/gold-silver/hard-versus-soft.vue")
  },
  {
    name: "products-gold-silver-history-of-gold",
    path: "/products/gold-silver/history-of-gold",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/gold-silver/history-of-gold.vue")
  },
  {
    name: "products-gold-silver",
    path: "/products/gold-silver",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/gold-silver/index.vue")
  },
  {
    name: "products-gold-silver-invest",
    path: "/products/gold-silver/invest",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/gold-silver/invest.vue")
  },
  {
    name: "products-gold-silver-investment-vs-collector",
    path: "/products/gold-silver/investment-vs-collector",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/gold-silver/investment-vs-collector.vue")
  },
  {
    name: "products-gold-silver-legal-tender",
    path: "/products/gold-silver/legal-tender",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/gold-silver/legal-tender.vue")
  },
  {
    name: "products-gold-silver-one-oz-gold",
    path: "/products/gold-silver/one-oz-gold",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/gold-silver/one-oz-gold.vue")
  },
  {
    name: "products-gold-silver-one-oz-silver",
    path: "/products/gold-silver/one-oz-silver",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/gold-silver/one-oz-silver.vue")
  },
  {
    name: "products-gold-silver-types-of-investment",
    path: "/products/gold-silver/types-of-investment",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/gold-silver/types-of-investment.vue")
  },
  {
    name: "products-payments-bullion-transfers",
    path: "/products/payments/bullion-transfers",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/payments/bullion-transfers.vue")
  },
  {
    name: "products-payments-bullion-vault-holdings",
    path: "/products/payments/bullion-vault-holdings",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/payments/bullion-vault-holdings.vue")
  },
  {
    name: "products-payments",
    path: "/products/payments",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/payments/index.vue")
  },
  {
    name: "products-payments-open-banking",
    path: "/products/payments/open-banking",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/payments/open-banking.vue")
  },
  {
    name: "products-payments-private-card",
    path: "/products/payments/private-card",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/payments/private-card.vue")
  },
  {
    name: "products-vault-trust-audits",
    path: "/products/vault-trust/audits",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/vault-trust/audits.vue")
  },
  {
    name: "products-vault-trust-bullion-access",
    path: "/products/vault-trust/bullion-access",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/vault-trust/bullion-access.vue")
  },
  {
    name: "products-vault-trust-external-bullion-deposits",
    path: "/products/vault-trust/external-bullion-deposits",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/vault-trust/external-bullion-deposits.vue")
  },
  {
    name: "products-vault-trust",
    path: "/products/vault-trust",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/vault-trust/index.vue")
  },
  {
    name: "products-vault-trust-insurance",
    path: "/products/vault-trust/insurance",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/vault-trust/insurance.vue")
  },
  {
    name: "products-vault-trust-vaults",
    path: "/products/vault-trust/vaults",
    component: () => import("/Users/mhy/Documents/GitLab/GS_website_new/GS_website_new/pages/products/vault-trust/vaults.vue")
  }
]