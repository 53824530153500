<script setup>
import { useMainStore } from "@/store";

const CustomInput = defineAsyncComponent(() => import('~/components/global/CustomInput.vue'))

const api = ref({});
const email = ref("");
const isValid = ref(false);
const reg =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
const store = useMainStore();

const getJson = async () => {
  try {
    const response = await fetch("/api.json", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      const data = await response.json();
      api.value = data;
    }
  } catch (error) {
    console.error("Error fetching JSON:", error);
  }
};

const inputChanged = (value) => {
  if (!value) {
    isValid.value = false;
  } else if (!reg.test(value)) {
    isValid.value = false;
  } else {
    isValid.value = true;
    email.value = value;
  }
};

const sumbit = async () => {
  if (isValid.value) {
    const formData = new FormData();
    formData.append("email", email.value);
    formData.append("action", "subscribe");

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    const url = `${api.value.MY_ACCOUNT.ENDPOINT}${api.value.MY_ACCOUNT.SEND_EMAIL}`;

    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        const data = await response.json();
        if (data) {
          store.setEmailSubscribe(true);
        } else {
          throw new Error("Something went wrong!");
        }
      }
    } catch (error) {
      console.error("Error during subscription:", error);
    }
  }
};

onBeforeMount(() => {
  getJson();
});
</script>

<template>
  <div class="emailSubscribe">
    <div class="emailSubscribe__input" v-if="!store.emailSubscribe">
      <CustomInput
        label="component.Email"
        @input-changed="inputChanged"
        placeholder="name@mail.com"
      />
    </div>
    <div class="emailSubscribe__label" v-if="!store.emailSubscribe">
      {{ $t("component.emailSubscribe__label") }}
    </div>
    <div class="emailSubscribe__submit" v-if="!store.emailSubscribe">
      <div
        class="emailSubscribe__submit_button"
        @click="sumbit"
        :class="{ emailSubscribe__submit_button_active: isValid }"
      >
        {{ $t("component.OK") }}
      </div>
    </div>
    <div class="emailSubscribe__title" v-if="store.emailSubscribe">
      {{ $t("component.Success") }}
    </div>
    <div class="emailSubscribe__desc" v-if="store.emailSubscribe">
      {{ email }}
      <br />
      {{ $t("component.emailSubscribe__desc") }}
      <br />
      {{ $t("component.emailSubscribe__desc1") }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.emailSubscribe {
  display: grid;
  grid-template-areas: "input input" "label submit" "desc desc";
  grid-template-columns: 50% 50%;

  &__title {
    grid-area: input;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: $white;
  }

  &__desc {
    grid-area: desc;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: -0.02em;
    color: $darkGrey;
  }

  &__input {
    grid-area: input;
  }

  &__label {
    grid-area: label;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: -0.02em;
    color: $darkGrey;
    padding-top: 10.815%;
  }

  &__submit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-area: submit;
    padding-left: 10%;
    padding-top: 10.815%;

    &_button {
      cursor: not-allowed;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $black;
      color: $white;
      border: 2px solid $white;
      border-radius: 60px;
      width: 60px;
      height: 60px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 143%;
      letter-spacing: -0.02em;
      text-transform: uppercase;

      transition: all linear 0.5s;
      -webkit-transition: all linear 0.5s;

      &_active {
        background-color: $white;
        color: $black;
        cursor: pointer;
      }
    }
  }
}
</style>
