<script setup>

const width = ref(5.5);
const radius = ref(15.225);
const circleCenter = ref(65);

const cosinus = computed(() => Math.cos(Math.PI / 4));

</script>

<template>
  <svg class="logo logo_little">
    <line
      :x1="circleCenter + cosinus * radius"
      :y1="circleCenter - cosinus * radius"
      :x2="circleCenter + cosinus * radius"
      :y2="circleCenter - cosinus * radius"
      stroke="white"
      :stroke-width="width"
    />
    <line
      :x1="circleCenter"
      :y1="circleCenter - radius"
      :x2="circleCenter"
      :y2="circleCenter - radius"
      stroke="white"
      :stroke-width="width"
    />
    <line
      :x1="circleCenter - cosinus * radius"
      :y1="circleCenter - cosinus * radius"
      :x2="circleCenter - cosinus * radius"
      :y2="circleCenter - cosinus * radius"
      stroke="white"
      :stroke-width="width"
    />
    <line
      :x1="circleCenter - radius"
      :y1="circleCenter"
      :x2="circleCenter - radius"
      :y2="circleCenter"
      stroke="white"
      :stroke-width="width"
    />
    <line
      :x1="circleCenter - cosinus * radius"
      :y1="circleCenter + cosinus * radius"
      :x2="circleCenter - cosinus * radius"
      :y2="circleCenter + cosinus * radius"
      stroke="white"
      :stroke-width="width"
    />
    <line
      :x1="circleCenter"
      :y1="circleCenter + radius"
      :x2="circleCenter"
      :y2="circleCenter + radius"
      stroke="white"
      :stroke-width="width"
    />
    <line
      :x1="circleCenter + cosinus * radius"
      :y1="circleCenter + cosinus * radius"
      :x2="circleCenter + cosinus * radius"
      :y2="circleCenter + cosinus * radius"
      stroke="white"
      :stroke-width="width"
    />
    <line
      :x1="125"
      :y1="circleCenter"
      x2="125"
      :y2="circleCenter"
      stroke="white"
      :stroke-width="width"
    />
  </svg>
</template>

<style lang="scss" scoped>

.logo {
  width: 130px;
  height: 130px;
  animation: slidein 0.3s;
  cursor: pointer;
  pointer-events: auto;

  &_large {
    transform-origin: left top;
    transform: scale(0.9231);
    transition: all 0.5s ease-in-out;
    padding: 0;
  }

  &_little {
    transform-origin: left top;
    transform: scale(0.6154);
    transition: all 0.5s ease-in-out;
  }

  @keyframes slidein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

@media (max-width: 1023px) {
  .logo {
    transform: scale(0.46);

    &_large,
    &_little {
      transform-origin: left top;
      transform: scale(0.46);
      transition: none;
      padding: 0;
    }
  }
}
</style>