<script setup>
import { useRoute } from "vue-router";
import { useCategsStore } from "@/store/categs";
import { useWhiteStore } from "@/store/whitePages";
import { useMainStore } from "@/store";

const route = useRoute();
const categs = useCategsStore();
const whiteStore = useWhiteStore();
const store = useMainStore();

const metaTitle = ref("Golden Suisse");

const changeLayout = () => {
  const whitePath = route.path.split("/");
  if (route.path === "/") {
    return "default";
  } else if (whitePath.length >= 4 && whitePath.pop() !== "form") {
    return "white";
  } else {
    return "other";
  }
};

const filterMeteTitle = () => {
  const routePath = route.path.split("/");
  const whitePath = route.path.split("/")[2];

  if (routePath.length <= 3 && route.path !== "/") {
    const routerInfo = categs.categs[routePath[1]].filter(
      (item) => item.path === route.path
    );
    if (routerInfo[0]?.title) {
      const result = routerInfo[0]?.title.replace(/_/g, " ");
      return `Golden Suisse - ${result}`;
    } else {
      const result = routerInfo[0]?.head;
      return `Golden Suisse - ${result}`;
    }
  } else if (routePath.length >= 4) {
    const routerInfo = whiteStore.list[whitePath].filter(
      (item) => item.path === route.path.split("/").pop()
    );
    const result = routerInfo[0]?.title.replace(/_/g, " ");
    return `Golden Suisse - ${result}`;
  }
  return "Golden Suisse";
};

onMounted(() => {
  metaTitle.value = filterMeteTitle();
});

watch(
  () => route.fullPath,
  (newPath) => {
    metaTitle.value = filterMeteTitle();
  }
);

useHead({
  title: metaTitle,
  meta: [{ name: "description", content: `You are viewing the ${metaTitle}` }],
});
</script>
<template>
  <NuxtLayout :name="changeLayout()"> </NuxtLayout>
</template>