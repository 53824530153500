import { useMainStore } from '@/store'

export default defineNuxtPlugin((nuxtApp) => {
  const mainStore = useMainStore()

  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    // handle error, e.g. report to a service

    if (import.meta.client) {
      // mainStore.changeLayout('error')
      console.log(error)
      console.log(instance)
      console.log(info)
    }
  }
})
