import { defineStore } from 'pinia';

export const useWhiteStore = defineStore('white', {
  state: () => ({
    list: {
      'card': [
        {
          path: 'gold-reserve-card',
          title: "Gold_Reserve_Card",
          exist: true
        },
        {
          path: "lifestyle-and-travel-manager",
          title: "Lifestyle_and_Travel_Manager",
          exist: true
        },
        {
          path: "private-transactions",
          title: "Private_Transactions",
          exist: true
        },
        {
          path: "priority-pass",
          title: "Priority_Pass",
          exist: true
        },
        {
          path: "interest",
          title: "Interest",
          exist: true
        },
        {
          path: "global-roaming",
          title: "Global_Roaming",
          exist: true
        },
        {
          path: "unlimited",
          title: "Unlimited",
          exist: true
        },
        {
          path: "fast-track",
          title: "Fast_Track",
          img1: "image/main/goldCircle.webp",
          img2: "image/main/whiteCircle.webp",
          exist: true
        },
        {
          path: "mastercard-world-elite",
          title: "Mastercard_World_Elite_T_C",
          exist: true
        },
        {
          path: "spending-abroad",
          title: "spending_abroad",
          exist: true
        },
        {
          path: "club",
          title: "The_Club",
          exist: false
        },
      ],
      'gold-silver': [
        {
          path: `one-oz-silver`,
          title: "Silver_Eagle",
          exist: true,
        },
        {
          path: `one-oz-gold`,
          title: "Gold_Eagle",
          exist: true,
        },
        {
          path: `invest`,
          title: "Invest_In_Metals",
          exist: true,
        },
        {
          path: `types-of-investment`,
          title: "Types_of_Investment",
          exist: true,
        },
        {
          path: `investment-vs-collector`,
          title: "Investment_vs_Collector",
          exist: true,
        },
        {
          path: `coins-vs-bars`,
          title: "Coins_vs_Bars",
          exist: true,
        },
        {
          path: `history-of-gold`,
          title: "History_of_Gold",
          exist: true,
        },
        {
          path: `legal-tender`,
          title: "Legal_Tender",
          exist: true,
        },
        {
          path: `hard-versus-soft`,
          title: "Hard_vs_Soft_Currency",
          exist: true,
        },
      ],
      'vault-trust': [
        {
          path: "vaults",
          title: "The_Vaults",
          exist: true,
        },
        {
          path: "bullion-access",
          title: "Bullion_Access",
          exist: true,
        },
        {
          path: "audits",
          title: "Audits",
          exist: true,
        },
        {
          path: "insurance",
          title: "Insurance",
          exist: true,
        },
        {
          path: "external-bullion-deposits",
          title: "External_Bullion_Deposits",
          exist: true,
        },
      ],
      'sustainability': [
        {
          path: "children",
          title: "Children",
          exist: true,
        },
        {
          path: "challenge",
          title: "Challenge",
          exist: true,
        },
        {
          path: "mission",
          title: "Mission",
          exist: true,
        },
        {
          path: "approach",
          title: "Approach",
          exist: true,
        },
      ],
      'benefits': [
        { title: 'Currency_Risk', path: 'currency-risk', exist: true },
        { title: 'Geopolitical_Risk', path: 'geopolitical-risk', exist: true },
        { title: 'Deposit_Risk', path: 'deposit-risk', exist: true },
        { title: 'Data_Privacy', path: 'data-privacy', exist: true },
        { title: 'Financial_Risk', path: 'financial-risk', exist: true },
        { title: 'Data_Security', path: 'data-security', exist: true },
        { title: "Risk_and_Return", path: "risk-and-return", exist: true },
        { title: "Tax_Benefits", path: "tax-benefits", exist: true },
      ],
      'payments': [
        { title: 'Bullion_Vault_Holdings', path: 'bullion-vault-holdings', exist: true },
        { title: 'Private_Card', path: 'private-card', exist: true },
        { title: 'Bullion_Transfers', path: 'bullion-transfers', exist: true },
        { title: 'Open_Banking', path: 'open-banking', exist: true },
      ],
      'become-partner': [
        { title: 'Private_Banks', path: 'private-banks', exist: true },
        { title: 'Bullion_Dealers', path: 'bullion-dealers', exist: true },
        { title: 'Wealth_Managers', path: 'wealth-managers', exist: true },
        { title: 'Refineries', path: 'refineries', exist: true },
      ]
    }
  }),
  getters: {
    getPagesArray: state => payload => {
      if (state.list.hasOwnProperty(payload)) return state.list[payload].filter(page => page.exist);
    },
    getPagesArrayByPaths: state => payload => {
      if (state.list.hasOwnProperty(payload.categ)) return state.list[payload.categ].filter(page => {
        let result = payload.list.find(targetPath => targetPath == page.path);
        if (result) return true;
      });
    },
  }
});