import { defineStore } from 'pinia';

export const useCategsStore = defineStore('categs', {
  state: () => ({
    categs: {
      account: [
        { path: "/account", title: "Login", exist: true, },
        { title: "Become_a_Client", path: "/account/register", exist: true, },
        { path: "/account/download", title: "Download_Apps", exist: true, },
        { path: "/account/authorize",head:"Authorize", exist: true },
        { path: "/account/forgot",head:"Forgot", exist: true, },
        { path: "/account/code-confirm",head:"Code Confirm", exist: true, },
      ],
      contact: [
        { path: "/contact", title: "Headquarters", exist: true, },
        { path: "/contact/support", title: "Support", exist: true, },
      ],
      legal: [
        { path: "/legal/vqf-membership", title: "VQF_Membership", exist: true, },
        { path: "goldensuisse_terms.pdf", title: "Terms", pdf: true, exist: true, },
        { path: "goldensuisse_aml.pdf", title: "AML", pdf: true, exist: true, },
        { path: "/legal/privacy", title: "Privacy", exist: true, },
        { path: "goldensuisse_privacypolicy.pdf", title: "Privacy_Policy", pdf: true, exist: true, },
        { path: "/legal/information", title: "Information", exist: true, },
      ],
      products: [
        { title: "Gold_and_Silver", path: "/products/gold-silver", exist: true },
        { title: 'Payments', path: '/products/payments', exist: true },
        { title: "Vault_and_Trust", path: "/products/vault-trust", exist: true },
        { title: "Card", path: "/products/card", exist: true },
        { title: "Advisory", path: "/products/advisory", exist: true },
        { title: "Benefits", path: "/products/benefits", exist: true },
      ],
      company: [
        { title: "Company", path: "/company", exist: true },
        { title: "Governance", path: "/company/governance", exist: true },
        { title: "Sustainability", path: "/company/sustainability", exist: true },
        { title: "Become_a_Partner", path: "/company/become-partner", exist: true },
        { title: "Rates", path: "/company/rates", exist: true },
        { title: "Awards", path: "/company/awards", exist: true },
      ],
      discover: [
        { title: 'Wealth_Protection', path: '/products/payments', exist: true },
        { title: 'Card', path: '/products/card', exist: true }
      ],
      'privacy-policy': [
        { path: "/privacy-policy", title: "Privacy_Policy", exist: true, },
      ],
    }
  }),
  getters: {
    getPage: state => payload => {
      let result;
      for (let categ in state.categs) {
        result = state.categs[categ].find(page => page.path == payload);
        if (result) return result;
      }
    },
    getOnlyMenusPages: state => payload => {
      let copeCategsObj = JSON.parse(JSON.stringify(state.categs));
      for (let categ in copeCategsObj) {
        copeCategsObj[categ].forEach(function (page, index) {
          if (page.notMenu) copeCategsObj[categ].splice(index, 1);
        });
      }
      return copeCategsObj;
    }
  }
});