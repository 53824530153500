// import gsap from "gsap";
import { gsap } from "gsap";

// console.log(gsap.to)

export default {
  addClassAnimDone: function (animEl) {
    animEl.setAttribute("data-anim-done", true);
  },
  fadeIn: function (animEl, animDelay) {
    gsap.to(animEl, {
      opacity: 1,
      transform: "translate(0, 0)",
      onComplete: this.addClassAnimDone(animEl),
      delay: animDelay,
      duration: 1
    });
  },
  fadeInSimple: function (animEl, animDelay) {
    gsap.to(animEl, {
      opacity: 1,
      onComplete: this.addClassAnimDone(animEl),
      delay: animDelay,
      duration: 1
    });
  },
  fadeVideo: function (animEl, animTo) {
    gsap.to(animEl, {
      opacity: animTo,
      transform: "translate(0, 0)",
      onComplete: this.addClassAnimDone(animEl),
      delay: 0.6,
      duration: 0.7
    });
  },
  videoPlayButton: function (animEl) {
    let width = animEl.dataset.animSvgWidth;
    let height = animEl.dataset.animSvgHeight;
    let stroke = animEl.dataset.animSvgStroke;
    gsap.to(animEl.children[2], {
      opacity: 1,
      attr: {
        y2: height - stroke
      },
      delay: 0.3,
      duration: 0.3
    });
    gsap.to(animEl.children[0], {
      opacity: 1,
      attr: {
        x2: width - stroke,
        y2: height / 2
      },
      delay: 0.3,
      duration: 0.6
    });
    gsap.to(animEl.children[1], {
      opacity: 1,
      attr: {
        x2: width - stroke,
        y2: height / 2
      },
      onComplete: this.addClassAnimDone(animEl),
      delay: 0.6,
      duration: 0.3
    });
  },
  flowButton: function (animEl) {
    gsap.to(animEl.children[0], {
      display: "flex",
      onComplete: this.addClassAnimDone(animEl),
    });
  },
  backgroundSlide: function (animEl) {
    gsap.to(animEl, {
      backgroundPosition: "50% 50%",
      onComplete: this.addClassAnimDone(animEl),
      duration: 0.7
    });
  },
  circleLeft: function (animEl) {
    gsap.to(animEl, {
      opacity: 1,
      x: "0%",
      delay: 0.3,
      duration: 0.7
    });
  },
  circleRight: function (animEl) {
    gsap.to(animEl, {
      opacity: 1,
      x: "0%",
      delay: 0.3,
      duration: 0.7
    });
  },
  scaleCard: function (animEl) {
    gsap.to(animEl, {
      onComplete: this.addClassAnimDone(animEl),
      delay: 0.7,
      opacity: 1,
      scale: 1,
      duration: 0.7
    });

  },
  scalePicture: function (animEl, animDelay) {
    gsap.to(animEl, {
      onComplete: this.addClassAnimDone(animEl),
      delay: animDelay || 0.3,
      opacity: 1,
      scale: 1,
      duration: 0.7
    });
  },
  animateHeader: function () {
    let logos = document.querySelector(".header__logo");
    if (logos) {
      const logo = logos.children[0];
      gsap.to(logo.children[0], {
        attr: {
          x2: 125,
          y2: 5
        },
        duration: 0.7
      });
      gsap.to(logo.children[1], {
        attr: {
          y2: 0
        },
        duration: 0.7
      });
      gsap.to(logo.children[2], {
        attr: {
          x2: 5,
          y2: 5
        },
        duration: 0.7
      });
      gsap.to(logo.children[3], {
        attr: {
          x2: 0
        },
        duration: 0.7
      });
      gsap.to(logo.children[4], {
        attr: {
          x2: 0,
          y2: 125
        },
        duration: 0.7

      });
      gsap.to(logo.children[5], {
        attr: {
          y2: 130
        },
        duration: 0.7
      });
      gsap.to(logo.children[6], {
        attr: {
          y2: 125,
          x2: 125
        },
        duration: 0.7
      });
      gsap.to(logo.children[7], {
        attr: {
          x2: 65 - 5.5 / 2
        },
        duration: 0.7
      });
    }
    this.animateTitle()
    this.animateMenu()
    this.animateLanguage()
  },
  animateTitle: function (animEl) {
    if (animEl) {
      gsap.to(animEl, {
        opacity: 1,
        duration: 0.7,
        y: 0,
        delay: 0.3,
      });
    }

  },
  animateMenu: function (animEl) {
    if (animEl) {
      gsap.to(animEl.children[0], {
        attr: {
          x2: 60
        },
        delay: 0.3,
        duration: 0.7,
        opacity: 1,
      });
      gsap.to(animEl.children[1], {
        attr: {
          x2: 0
        },
        delay: 0.3,
        duration: 0.7,
        opacity: 1,
      });
    }

  },
  animateLanguage: function (animEl) {
    if (animEl) {
      gsap.to(animEl, {
        delay: 0.3,
        scale: 1,
        opacity: 1,
        duration: 0.7
      });
    }
  },
  animateSubNavbar: function () {
    let headerSubNavbar = document.querySelector(".header__subNavbar");
    if (headerSubNavbar) {
      headerSubNavbar = headerSubNavbar.children[0].children;
      for (let i = 0; i < headerSubNavbar.length; i++) {
        headerSubNavbar[i].style.opacity = 0
        gsap.to(headerSubNavbar[i], {
          opacity: 1,
          delay: i / 10 + 0.8,
          duration: 0.7,
        });
      }
    }
  },
  destroySubNavbar: function () {
    let headerSubNavbar = document.querySelector(".header__subNavbar");

    if (headerSubNavbar) {
      headerSubNavbar = headerSubNavbar.children[0].children;
      for (let i = 0; i < headerSubNavbar.length; i++) {
        gsap.to(headerSubNavbar[i], {
          opacity: 0,
          duration: 0.7,
        });
      }
    }
  },
  animContentLoader: function () {
    let logos = document.querySelector(".mainLoader__logo");

    if (!logos) return
    const logo = logos.children[0]
    gsap.to(logo.children[0], {
      duration: 0.4,
      attr: {
        x2: 125,
        y2: 5
      }
    });
    gsap.to(logo.children[1], {
      duration: 0.4,
      attr: {
        y2: 0
      }
    });
    gsap.to(logo.children[2], {
      duration: 0.4,
      attr: {
        x2: 5,
        y2: 5
      }
    });
    gsap.to(logo.children[3], {
      duration: 0.4,
      attr: {
        x2: 0
      }
    });
    gsap.to(logo.children[4], {
      duration: 0.4,
      attr: {
        x2: 0,
        y2: 125
      }
    });
    gsap.to(logo.children[5], {
      duration: 0.4,
      attr: {
        y2: 130
      }
    });
    gsap.to(logo.children[6], {
      duration: 0.4,
      attr: {
        y2: 125,
        x2: 125
      }
    });
    gsap.to(logo.children[7], {
      duration: 0.4,
      attr: {
        x2: 65 - 5.5 / 2
      }
    });
    this.addClassAnimDone(logo);

    let title = document.querySelector(".mainLoader__title");
    if (title) {
      gsap.to(title, {
        opacity: 1,
        onComplete: this.addClassAnimDone(title),
      });
    }

    let subtitle = document.querySelector(".mainLoader__subtitle");
    if (subtitle) {
      gsap.to(subtitle, {
        opacity: 1,
        onComplete: this.addClassAnimDone(title),
      });
    }

    

  },
  cardIn: function (animEl) {
    let circles = animEl.querySelectorAll('.card__circle');
    let card = animEl.querySelector('.card__img');
    gsap.to(circles, {
      opacity: 1,
      x: '0%',
      duration: .7
    });
    gsap.to(card, {
      opacity: 1,
      scale: 1,
      delay: .3,
      duration: .7,
      onComplete: this.addClassAnimDone(animEl),
    });
  },
  list: function (animEl) {
    let items = animEl.querySelectorAll('.list__item');
    let tl = gsap.timeline();
    if (items.length === 0) return false
    tl.to(items, {
      opacity: 1,
      onComplete: this.addClassAnimDone(animEl),
      duration: 1,
      stagger: 0.2,
    })
  },
  spendingAbroadRate: function () {
    const ecbRate = document.querySelector('.ecbRate');
    const schemeRate = document.querySelector('.schemeRate');
    const partnerRate = document.querySelector('.partnerRate');
    const partnerMarkupOverEcb = document.querySelector('.partnerMarkupOverEcb');
    gsap.fromTo(
      ecbRate,
      { opacity: 0, },
      { opacity: 1, delay: 0.3, }
    );
    gsap.fromTo(
      schemeRate,
      { opacity: 0, },
      { opacity: 1, delay: 0.3, }
    );
    gsap.fromTo(
      partnerRate,
      { opacity: 0 },
      { opacity: 1, delay: 0.3, }
    );
    gsap.fromTo(
      partnerMarkupOverEcb,
      { opacity: 0 },
      { opacity: 1, delay: 0.3, }
    );

  }
};
