<script setup>
import Logo from "./Logo.vue";
import OtherHeaderTitle from "./OtherHeaderTitle.vue";
import SmallNavbar from "./SmallNavbar.vue";
import MenuIcon from "./MenuIcon.vue";

const LanguageButton = defineAsyncComponent(() => import('~/components/global/LanguageButton.vue'))
const Links = defineAsyncComponent(() => import('~/components/global/Links.vue'))

const props = defineProps({
  isMenu: {
    type: Boolean,
    default: false,
  },
  openedMenu: {
    type: Boolean,
  },
  linkList: {
    type: Array,
  },
  isError: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["close-menu", "open-menu"]);

const openMenu = () => {
  emit("open-menu");
  let subnav = document.querySelector(".header__subNavbar");
  subnav.style.removeProperty("border-top");
};

const closeMenu = () => {
  emit("close-menu");
};
</script>

<template>
  <div class="header">
    <div class="header__container">
      <section class="header__logos">
        <NuxtLink class="header__logo" to="/">
          <Logo />
        </NuxtLink>
      </section>

      <section class="header__title" data-anim="animateTitle">
        <OtherHeaderTitle />
      </section>
    </div>

    <section class="header__menu">
      <MenuIcon
        @open-menu="openMenu"
        @close-menu="closeMenu"
        :isMenu="isMenu"
      />
      <div class="header__language" data-anim="animateLanguage">
        <!-- <LanguageButton /> -->
      </div>
    </section>

    <section class="header__navbar">
      <Transition name="fadeSmallNavbar">
        <SmallNavbar />
      </Transition>
    </section>

    <Transition name="fade">
      <hr v-show="linkList && !isMenu" class="header__hr" height="2" />
    </Transition>

    <Transition name="fade">
      <section v-show="linkList && !isMenu" class="header__subNavbar">
        <Links :linkList="linkList" />
      </section>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: grid;
  grid-template-areas:
    ". container container container menu navbar navbar navbar navbar ."
    ". hr hr hr hr hr hr hr hr ."
    ". subNavbar subNavbar subNavbar subNavbar subNavbar subNavbar subNavbar subNavbar .";
  grid-template-columns: 3.75% repeat(8, 11.5625%) 3.75%;
  z-index: 3;
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.3s ease-in-out;
  background-color: $black;
  padding: 30px 0 0 0;

  &__container {
    grid-area: container;
    display: inline-flex;
  }

  &__logos {
    grid-area: logos;
    color: $white;
    height: inherit;
  }

  &__title {
    height: inherit;
    grid-area: title;
    width: fit-content;
    opacity: 0;
    transform: translateY(20%);
  }

  &__menu {
    height: inherit;
    grid-area: menu;
    display: flex;
    /*justify-content: flex-start;*/
    flex-direction: column;
    align-items: center;
    z-index: 4;
    position: relative;
    @include adaptive(notMob) {
      .menu {
        margin-right: -30px;
      }
    }
  }

  &__navbar {
    height: inherit;
    grid-area: navbar;
    position: relative;
  }

  &__language {
    position: absolute;
    top: -1px;
    right: -180px;
    opacity: 0;
    transform: scale(0);
  }

  &__hr {
    grid-area: hr;
  }

  &__subNavbar {
    grid-area: subNavbar;
    height: 80px;
    z-index: 4;
    transition: 1s all ease-in-out;
  }
}

.fadeSmallNavbar-leave-active {
  transition: opacity 0.3s;
}

.fadeSmallNavbar-leave-to {
  opacity: 0;
}

.fadeHr-enter-active {
  transition: opacity 0.5s;
}

.fadeHr-leave-active {
  transition: opacity 0.5s;
  transition-delay: 0s;
}

.fadeHr-enter,
.fadeHr-leave-to {
  opacity: 0;
}

@media (max-width: 1023px) {
  .header {
    grid-template-columns: 5.5% 20% 1fr 1fr 50px 5.5%;
    grid-template-areas: ". container title title menu .";
    transition: all 0.2s ease-in-out;
    height: 100px;
    padding: 20px 0 20px 0;

    &__logos {
      height: 60px;
      width: 80px;
    }

    &__menu {
      display: flex;
      padding: 0;
      margin: 0;
      width: 100%;
      position: relative;
    }

    &__language {
      right: 80px;
    }

    &__navbar {
      display: none;
    }

    &__subNavbar {
      display: none;
    }

    &__hr {
      display: none;
    }
  }
}

@media (max-width: 580px) {
  .header {
    &__language {
      right: 60px;
    }

    &__logos {
      height: 55px;
      width: 70px;
    }
  }
}

@media (max-width: 385px) {
  .header {
    &__language {
      right: 50px;
    }

    &__logos {
      height: 50px;
      width: 60px;
    }
  }
}
</style>
