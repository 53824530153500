import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAccountButton, LazyAccountCustomInputAccount, LazyAccountLoginInput, LazyCardMap, LazyContactClock, LazyCustomInput, LazyCustomVideo, LazyFlowButton, LazyFlowButtonDark, LazyGoldCardType, LazyGoldCardMastercardInfo, LazyLanguageButton, LazyLinks, LazyLoading, LazyPlayButton, LazyProductsDoubleLinkList, LazyProductsMetalPrice, LazyProductsMaskGroup, LazyReason, LazySustainabilityChildrenSwiper, LazySustainabilityLinkList, LazySustainabilityMaskGroup, LazyUpdatedList, LazyUpdatedList2, LazyUpdatedListSm, LazyUpdatedMetalPrice, LazyUpdatedTableV1, LazyUpdatedTableV2, LazyUpdatedVideoPopup, LazyWhiteList } from '#components'
const lazyGlobalComponents = [
  ["AccountButton", LazyAccountButton],
["AccountCustomInputAccount", LazyAccountCustomInputAccount],
["AccountLoginInput", LazyAccountLoginInput],
["CardMap", LazyCardMap],
["ContactClock", LazyContactClock],
["CustomInput", LazyCustomInput],
["CustomVideo", LazyCustomVideo],
["FlowButton", LazyFlowButton],
["FlowButtonDark", LazyFlowButtonDark],
["GoldCardType", LazyGoldCardType],
["GoldCardMastercardInfo", LazyGoldCardMastercardInfo],
["LanguageButton", LazyLanguageButton],
["Links", LazyLinks],
["Loading", LazyLoading],
["PlayButton", LazyPlayButton],
["ProductsDoubleLinkList", LazyProductsDoubleLinkList],
["ProductsMetalPrice", LazyProductsMetalPrice],
["ProductsMaskGroup", LazyProductsMaskGroup],
["Reason", LazyReason],
["SustainabilityChildrenSwiper", LazySustainabilityChildrenSwiper],
["SustainabilityLinkList", LazySustainabilityLinkList],
["SustainabilityMaskGroup", LazySustainabilityMaskGroup],
["UpdatedList", LazyUpdatedList],
["UpdatedList2", LazyUpdatedList2],
["UpdatedListSm", LazyUpdatedListSm],
["UpdatedMetalPrice", LazyUpdatedMetalPrice],
["UpdatedTableV1", LazyUpdatedTableV1],
["UpdatedTableV2", LazyUpdatedTableV2],
["UpdatedVideoPopup", LazyUpdatedVideoPopup],
["WhiteList", LazyWhiteList],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
